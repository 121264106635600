import React, { ForwardedRef, ReactElement, useId, useState } from "react";

import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import classNames from "classnames";

export type SelectProps = {
  label?: string;
  height?: string;
  width?: string;
  validationState?: "error" | "warning" | "success";
} & Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "width" | "height" | "className">;

export const Select = React.forwardRef(
  (
    { id, label, width, height, disabled = false, children, validationState, ...props }: SelectProps,
    _ref?: ForwardedRef<HTMLSelectElement>
  ): ReactElement => {
    const uniqueId = useId();
    const [internalHasValue, setInternalHasValue] = useState<boolean>(props.defaultValue !== undefined && props.defaultValue !== "");
    const isControlledCompnent = props.value !== undefined;
    const hasValue = isControlledCompnent ? props.value !== "" : internalHasValue;

    const selectId = id ? id : `select-${uniqueId}`;

    return (
      <div className={`relative flex flex-col ${height ?? "h-12"} ${width ?? "w-full"}`}>
        <div className={classNames(`h-full w-full`)}>
          <select
            {...props}
            id={selectId}
            onChange={(e) => {
              if (!isControlledCompnent) {
                setInternalHasValue(e.currentTarget.value !== undefined);
              }
              props.onChange?.(e);
            }}
            className={classNames(
              "peer appearance-none pl-3 pr-[2rem] text-ellipsis focus:outline-none focus-visible:ring-0 border-transparent border-[1px] rounded h-full w-full text-[0px] focus:text-base",
              "focus:bg-white hover:border-primary-700 active:border-primary-700 focus:border-primary-700 bg-l-blue",
              "disabled:bg-xl-sky-grey disabled:border-xl-sky-grey disabled:border-[0px]",
              {
                "!bg-l-red hover:!border-m-red active:!border-m-red focus:!border-m-red": !disabled && validationState === "error",
                "!bg-l-yellow hover:!border-d-yellow active:!border-d-yellow focus:!border-d-yellow": !disabled && validationState === "warning",
                "text-base": hasValue,
                "pt-2": !!label && hasValue,
              }
            )}
          >
            {children}
          </select>
          {label && (
            <label
              htmlFor={selectId}
              className={classNames(
                "w-full max-w-full overflow-hidden whitespace-nowrap text-ellipsis absolute left-3 pr-12 pointer-events-none z-10 text-xs border-l border-transparent text-sky-grey peer-disabled:text-m-grey",
                "top-[50%] peer-focus:top-auto focus:text-xs peer-focus:text-xs peer-focus:pt-1",
                "translate-y-[-50%] peer-focus:translate-y-0 peer-focus:text-black",
                {
                  "!top-auto !translate-y-0 text-xs pt-1": hasValue,
                }
              )}
            >
              {label}
            </label>
          )}
          <div
            className={classNames(
              "h-fit w-fit absolute top-[50%] translate-y-[-50%] pointer-events-none px-2 right-0",
              "peer-focus:text-primary-700 peer-hover:text-primary-700",
              "peer-disabled:text-m-grey"
            )}
          >
            <ArrowIcon height={24} width={24} />
          </div>
        </div>
      </div>
    );
  }
);
