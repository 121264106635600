import { FeatureToggle } from "core/auth/FeatureToggle";
import { Route, Routes } from "react-router-dom";
import { AccountSecurityHome } from "./Modules/AccountSecurityHome/AccountSecurityHome";

export const AccountSecurity = () => {
  const showAccountSecurity = !!process.env.REACT_APP_SHOW_ACCOUNT_FEATURE_TOGGLE;

  return (
    <FeatureToggle showFeature={showAccountSecurity}>
      <Routes>
        <Route index element={<AccountSecurityHome />} />
      </Routes>
    </FeatureToggle>
  );
};
