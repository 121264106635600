import StylableButton from "shared/UI/Buttons/StylableButton";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import useIsMobile from "shared/core/hooks/useIsMobile";
import { AccountSecurityModule } from "../interfaces";
import { useNavigate } from "react-router-dom";
import { ModuleInfoAccordion } from "../ModuleInfoAccordion/ModuleInfoAccordion";

export const AccountSecurityModuleCard = ({ title, description, ctaText, ctaLink, info, Icon }: AccountSecurityModule) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <RoundedCard padding="p-0" className="text-lg lg:text-xl">
      <div className="flex md:items-center justify-between md:space-x-10 p-5 md:p-10 flex-col-reverse md:flex-row">
        <div className="flex-col justify-between space-y-8 max-w-4xl bg-indigo-500 mt-3 md:mt-0">
          <div className="flex-1">
            <h3 className="cardTitle">{title}</h3>
            <p>{description}</p>
          </div>
          <div className="mt-8 flex-1">
            <StylableButton
              className="orange-button padding-xl w-full"
              fullWidth={isMobile}
              text={ctaText}
              aria-label={title}
              size="xl"
              onClick={() => navigate(ctaLink)}
            />
          </div>
        </div>
        {Icon && (
          <div data-testid="account-security-module-icon" className="w-20 h-20 md:w-32 md:h-32 lg:w-48 lg:h-48">
            <Icon style={{ width: "inherit", height: "inherit" }} />
          </div>
        )}
      </div>
      {info && <ModuleInfoAccordion {...info} />}
    </RoundedCard>
  );
};
