import { Navigate, Route, Routes } from "react-router-dom";

import { AccessTokenState } from "shared/core/state/accessTokenState";
import { Account } from "./Views/Account/Account";
import { AdditionalInfo } from "./Views/AdditionalInfo/AdditionalInfo";
import { AnchorScroller } from "UIPalette/ScrollToTop/AnchorScroller";
import { AppLayout } from "UIPalette/AppLayout/AppLayout";
import { Auth } from "./Views/Auth/Auth";
import { BreadcrumbContextProvider } from "UIPalette/AppLayout/Components/Breadcrumbs/BreadcrumbContext";
import { Callback } from "./Views/Callback/Callback";
import { ClientCodeState } from "shared/core/state/clientCodeState";
import { ContactUs } from "./Views/ContactUs/ContactUs";
import { DatoContentPage } from "UIPalette/Components/DatoContentPage/DatoContentPage";
import { HealthHub } from "./Views/HealthHub/HealthHub";
import { Home } from "./Views/Home/Home";
import { Leaderboards } from "./Views/Leaderboards/Leaderboards";
import { LiveChatProvider } from "./Views/ContactUs/Components/LiveChatProvider";
import { LocaleIdToCodeMap } from "shared/core/locale";
import { LocaleModal } from "UIPalette/LocaleModal/LocaleModal";
import { Login } from "./Views/Login/Login";
import { LoginInfoLayout } from "UIPalette/LoginInfoLayout/LoginInfoLayout";
import { LoginLayout } from "UIPalette/LoginLayout/LoginLayout";
import { ModalProvider } from "core/state/ModalContext/ModalContext";
import { NotFound } from "./Views/NotFound";
import { Preferences } from "./Views/Preferences/Preferences";
import { ProtectedModuleRoute } from "core/auth/ProtectedModuleRoute";
import { Resources } from "./Views/Resources/Resources";
import { Toaster } from "shared/UI/Toaster/Toaster";
import { TrackerContextProvider } from "core/state/Trackers/TrackerContext";
import WisdomModules from "core/Modules";
import { appInsightsLogger } from "core/monitoring/AppInsights";
import { authenticateMobileUser } from "core/javascriptChannels";
import { changeLanguage } from "i18next";
import { protect } from "core/auth/ProtectedRoute";
import { useAuth } from "core/auth/useAuth";
import { useEffect } from "react";
import useLocale from "core/hooks/useLocale/useLocale";
import { useMobilePlatform } from "core/hooks/useMobilePlatform";
import { useTokenRenewalContext } from "core/auth/TokenRenewalContext";
import { t } from "i18next";
import { trackEvents } from "core/monitoring/Events";

export const App = () => {
  appInsightsLogger.info("useAuth - App.tsx: Starting App component..");
  const { localeId } = useLocale();
  const { refreshToken, accessToken, isAuthenticated } = useAuth();
  const { renewToken } = useTokenRenewalContext();
  const { isMobileApp } = useMobilePlatform();

  useEffect(() => {
    document.body.style.backgroundColor = "#fff";

    return () => {
      document.body.style.backgroundColor = "#277E8B";
    };
  }, []);

  useEffect(() => {
    changeLanguage(LocaleIdToCodeMap[localeId]);
  }, [localeId]);

  useEffect(() => {
    if (!window.flutter_inappwebview) {
      document.addEventListener("visibilitychange", renewToken);
    }

    return () => {
      if (!window.flutter_inappwebview) {
        document.removeEventListener("visibilitychange", renewToken);
      }
    };
  });

  useEffect(() => {
    if (isMobileApp && isAuthenticated && !!refreshToken && !!accessToken) {
      if (ClientCodeState.isDemoClient()) {
        appInsightsLogger.info(
          `Calling autheticateMobileUser - App.tsx: refreshToken: ${refreshToken} accessToken:${accessToken}, clientCode: ${ClientCodeState.get()}`
        );
      }
      const userObject = AccessTokenState.getUser();
      const clientCode = ClientCodeState.get();
      if (clientCode && userObject) {
        authenticateMobileUser(clientCode, userObject?.toStorageString());
      }
      trackEvents("Authenticate_Mobile_User", {
        timeStamp: userObject?.profile.iat.toString() ?? "",
        expires: userObject?.expires_at?.toString() ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken]);

  return (
    <BreadcrumbContextProvider>
      <ModalProvider>
        <AnchorScroller />
        <LocaleModal />
        <Toaster />
        <Routes>
          <Route
            element={protect(
              <TrackerContextProvider>
                <AppLayout />
              </TrackerContextProvider>
            )}
          >
            <Route index element={<Navigate replace to="/home" />} />
            <Route path="home" element={<Home />} />
            <Route path="health-hub/*" element={<HealthHub />} />
            <Route element={<ProtectedModuleRoute wisdomModule={WisdomModules.leaderBoards} />}>
              <Route path="leaderboards/*" element={<Leaderboards />} />
            </Route>
            <Route path="resources/*" element={<Resources />} />
            <Route path="account/*" element={<Account />} />
            <Route
              path="contact-us"
              element={
                <LiveChatProvider>
                  <ContactUs />
                </LiveChatProvider>
              }
            />
            <Route path="contact-us/callback" element={<Callback />} />
          </Route>
          <Route element={<LoginLayout />}>
            <Route path="additional-info" element={protect(<AdditionalInfo />)} />
            <Route path="preferences" element={protect(<Preferences />)} />
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="auth" element={<Auth />} />
          <Route element={<LoginInfoLayout />}>
            <Route path="terms" element={<DatoContentPage slug={"terms"} pageTitle={t("pageTitle.terms")} />} />
            <Route path="privacy" element={<DatoContentPage slug={"privacy"} pageTitle={t("pageTitle.privacy")} />} />
            <Route path="cookie-policy" element={<DatoContentPage slug={"cookie-policy"} pageTitle={t("pageTitle.cookiePolicy")} />} />
            <Route path="login-help" element={<DatoContentPage slug={"login-help"} pageTitle={t("pageTitle.loginHelp")} />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ModalProvider>
    </BreadcrumbContextProvider>
  );
};
