import { AxiosRequestConfig, AxiosResponse } from "axios";
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISWRConfig, useGetRequest } from "../core/hooks/useGetRequest";
import { SWRMutationConfig, useMutationRequest } from "../core/hooks/useMutationRequest";

import { IResponseError } from "./IResponseError";

/*
 *  this file is generated by @openapi-integration/swr-request-generator.
 *  please do not modify it manually.
 */

export const useCompleteMiniHealthCheckFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<CompleteMiniHealthCheckFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<CompleteMiniHealthCheckFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Content/MiniHealthChecks/Complete`,
    method: "post",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useConfirmClientCodeFunctionRequest = (
  {
    clientCode,
  }: {
    clientCode?: string;
  },
  SWRConfig?: ISWRConfig<ConfirmClientCodeResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<ConfirmClientCodeResponseModel, IResponseError>(
    {
      url: `/User/ConfirmClient/${clientCode}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig
  );

export const useContactSupportFunctionRequest = (
  mutationConfig?: SWRMutationConfig<ContactSupportFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<ContactSupportFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Contact/Support`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useCreateClientAdminUserFunctionRequest = (
  mutationConfig?: SWRMutationConfig<CreateClientAdminUserFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<CreateClientAdminUserFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>({
    url: `/UserAdmin/ClientCreate`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useCreateClientFunctionRequest = (
  mutationConfig?: SWRMutationConfig<CreateClientFunctionRequest, AxiosResponse<ClientResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<CreateClientFunctionRequest, AxiosResponse<ClientResponseModel>, IResponseError>({
    url: `/Clients/Create`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useCreateHaAdminUserFunctionRequest = (
  mutationConfig?: SWRMutationConfig<CreateHaAdminUserFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<CreateHaAdminUserFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>({
    url: `/UserAdmin/HACreate`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useCreateLeaderboardFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<CreateLeaderboardFunctionRequest, AxiosResponse<CreateLeaderboardResponse>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<CreateLeaderboardFunctionRequest, AxiosResponse<CreateLeaderboardResponse>, IResponseError>({
    url: `/Leaderboard`,
    method: "post",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useCurrentUserFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<UserResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<UserResponseModel, IResponseError>(
    {
      url: `/User/Current`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useDeleteClientAdminUserFunctionRequest = (
  mutationConfig?: SWRMutationConfig<DeleteClientAdminUserFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<DeleteClientAdminUserFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/UserAdmin/ClientDelete`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useDeleteClientFunctionRequest = (
  mutationConfig?: SWRMutationConfig<DeleteClientFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<DeleteClientFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Clients/Delete`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useDeleteHaAdminUserFunctionRequest = (
  mutationConfig?: SWRMutationConfig<DeleteHaAdminUserFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<DeleteHaAdminUserFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/UserAdmin/HADelete`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useDeleteImageFunctionRequest = (
  mutationConfig?: SWRMutationConfig<DeleteImageFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<DeleteImageFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/UserAdmin/Images/Delete`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useDeleteTargetGroupFunctionRequest = (
  mutationConfig?: SWRMutationConfig<DeleteTargetGroupFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<DeleteTargetGroupFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/TargetGroup/Delete`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useDeleteUserTrackerValueFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<DeleteUserTrackerValueFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<DeleteUserTrackerValueFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Tracker/Value`,
    method: "delete",
    headers: { "X-Client-Code?": xClientCode },
    mutationConfig,
    axiosConfig,
  });

export const useDeleteWisdomUserFunctionRequest = (
  mutationConfig?: SWRMutationConfig<DeleteWisdomUserFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<DeleteWisdomUserFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/UserAdmin/WisdomUser/Delete`,
    method: "delete",
    headers: {},
    mutationConfig,
    axiosConfig,
  });

export const useDownloadImageFunctionRequest = (
  {
    clientCode,
    fileName,
  }: {
    clientCode?: string;
    fileName?: string;
  },
  SWRConfig?: ISWRConfig<undefined, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<undefined, IResponseError>(
    {
      url: `/Images/${clientCode}/${fileName}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig
  );

export const useEditAdminUserAdditionalInfoFunctionRequest = (
  mutationConfig?: SWRMutationConfig<EditAdminUserAdditionalInfoFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<EditAdminUserAdditionalInfoFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>({
    url: `/UserAdmin/AdditionalInfo`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useEditClientAdminUserFunctionRequest = (
  mutationConfig?: SWRMutationConfig<EditClientAdminUserFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<EditClientAdminUserFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>({
    url: `/UserAdmin/ClientEdit`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useEditClientFunctionRequest = (
  mutationConfig?: SWRMutationConfig<EditClientFunctionRequest, AxiosResponse<ClientResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<EditClientFunctionRequest, AxiosResponse<ClientResponseModel>, IResponseError>({
    url: `/Clients/Edit`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useEditHaAdminUserFunctionRequest = (
  mutationConfig?: SWRMutationConfig<EditHaAdminUserFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<EditHaAdminUserFunctionRequest, AxiosResponse<AdminUserResponseModel>, IResponseError>({
    url: `/UserAdmin/HAEdit`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useFinishFourWeekPlanFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<FinishFourWeekPlanFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<FinishFourWeekPlanFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Content/FourWeekPlans/Finish`,
    method: "post",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useGetClientFunctionRequest = (
  {
    clientId,
  }: {
    clientId?: string;
  },
  SWRConfig?: ISWRConfig<ClientResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<ClientResponseModel, IResponseError>(
    {
      url: `/UserAdmin/Clients/${clientId}`,
      method: "get",
      headers: { "Content-Type": "application/json" },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetFourWeekPlanActivitiesFunctionRequest = (
  {
    datoFourWeekPlanId,
    xClientCode,
  }: {
    datoFourWeekPlanId?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<FourWeekPlanActivities, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<FourWeekPlanActivities, IResponseError>(
    {
      url: `/Content/FourWeekPlans/Activities/${datoFourWeekPlanId}`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetFourWeekPlanFunctionRequest = (
  {
    id,
    xClientCode,
  }: {
    id?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<FourWeekPlanSummaryWithTrackersAndCategories, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<FourWeekPlanSummaryWithTrackersAndCategories, IResponseError>(
    {
      url: `/Content/FourWeekPlans/${id}`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetLeaderboardFunctionRequest = (
  {
    leaderboardCode,
    xClientCode,
  }: {
    leaderboardCode?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<LeaderboardWithParticipants, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<LeaderboardWithParticipants, IResponseError>(
    {
      url: `/Leaderboard/${leaderboardCode}`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      params: {
        leaderboardCode,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetLeaderboardTemplateFunctionRequest = (
  {
    id,
    xClientCode,
  }: {
    id?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<Leaderboard, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<Leaderboard, IResponseError>(
    {
      url: `/Content/Leaderboards/${id}`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetLearningMaterialFunctionRequest = (
  {
    id,
    xClientCode,
  }: {
    id?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<LearningMaterial, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<LearningMaterial, IResponseError>(
    {
      url: `/Content/LearningMaterials/Id/${id}`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetMiniHealthCheckFunctionRequest = (
  {
    id,
    xClientCode,
  }: {
    id?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<UserMiniHealthCheck, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<UserMiniHealthCheck, IResponseError>(
    {
      url: `/Content/MiniHealthChecks/${id}`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetPageFunctionRequest = (
  {
    slug,
    xClientCode,
  }: {
    slug?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<Page, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<Page, IResponseError>(
    {
      url: `/Pages/GetPage/${slug}`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetPublicPageFunctionRequest = (
  {
    slug,
    localeId,
  }: {
    slug?: string;
    localeId?: string;
  },
  SWRConfig?: ISWRConfig<Page, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<Page, IResponseError>(
    {
      url: `/Pages/Public/GetPage/${slug}/${localeId}`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetReportFunctionRequest = (
  {
    reportType,
    dateFrom,
    dateTo,
    targetGroupClientIds,
    clientId,
    divisionId,
    groupBy,
    localeId,
  }: {
    clientId?: string;
    dateFrom: string;
    dateTo: string;
    divisionId?: string;
    localeId?: string;
    groupBy?: string;
    reportType: string;
    targetGroupClientIds?: string[];
  },
  SWRConfig?: ISWRConfig<ReportResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<ReportResponseModel, IResponseError>(
    {
      url: `/Reports/GetReport`,
      method: "get",
      headers: {},
      params: {
        reportType,
        dateFrom,
        dateTo,
        localeId,
        targetGroupClientIds,
        clientId,
        divisionId,
        groupBy,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetTrackersWithRelatedContentFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<TrackerWithRelatedContent[], IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<TrackerWithRelatedContent[], IResponseError>(
    {
      url: `/Trackers`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetUserAchievementsFunctionRequest = (
  {
    trackerType,
    from,
    to,
    xClientCode,
  }: {
    from?: string;
    to?: string;
    trackerType: TrackerTypeTrackerType;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<UserAchievements, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<UserAchievements, IResponseError>(
    {
      url: `/UserAchievements`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      params: {
        trackerType,
        from,
        to,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetUserFourWeekPlanProgressFunctionRequest = (
  {
    id,
    xClientCode,
  }: {
    id?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<UserFourWeekPlanProgress, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<UserFourWeekPlanProgress, IResponseError>(
    {
      url: `/Content/UserFourWeekPlanProgress/${id}`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetUserInterestPreferencesFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<UserInterestPreferences, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<UserInterestPreferences, IResponseError>(
    {
      url: `/User/Interests`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetUserLeaderboardsFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<UserLeaderboards, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<UserLeaderboards, IResponseError>(
    {
      url: `/Leaderboards/User`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetUserTrackerValuesFunctionRequest = (
  {
    trackerType,
    from,
    to,
    xClientCode,
  }: {
    from?: string;
    to?: string;
    trackerType?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<UserTrackerValues, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<UserTrackerValues, IResponseError>(
    {
      url: `/Tracker/Value`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      params: {
        trackerType,
        from,
        to,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useGetUsersPerClientFunctionRequest = (
  SWRConfig?: ISWRConfig<UsersPerClientResponse, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<UsersPerClientResponse, IResponseError>(
    {
      url: `/Reports/UsersPerClient`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig
  );

export const useImportClientsFunctionRequest = (
  mutationConfig?: SWRMutationConfig<ImportClientsFunctionRequest, AxiosResponse<ImportClientsResponse>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<ImportClientsFunctionRequest, AxiosResponse<ImportClientsResponse>, IResponseError>({
    url: `/Clients/Import`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    mutationConfig,
    axiosConfig,
  });

export const useJoinLeaderboardFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<JoinLeaderboardFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<JoinLeaderboardFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Leaderboard/Join`,
    method: "put",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useLeaveLeaderboardFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<LeaveLeaderboardFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<LeaveLeaderboardFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Leaderboard/Leave`,
    method: "put",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useListAdminPermissionsFunctionRequest = (
  {
    role,
  }: {
    role: string;
  },
  SWRConfig?: ISWRConfig<AdminPermissionsResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<AdminPermissionsResponseModel, IResponseError>(
    {
      url: `/UserAdmin/ListPermissions`,
      method: "get",
      headers: {},
      params: {
        role,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListAdminUsersFunctionRequest = (
  mutationConfig?: SWRMutationConfig<ListAdminUsersFunctionRequest, AxiosResponse<AdminUsersResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<ListAdminUsersFunctionRequest, AxiosResponse<AdminUsersResponseModel>, IResponseError>({
    url: `/UserAdmin/List`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useListBreathingExercisesFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<BreathingExercise[], IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<BreathingExercise[], IResponseError>(
    {
      url: `/Content/BreathingExercises`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListClientDivisionsFunctionRequest = (
  {
    clientId,
  }: {
    clientId: string;
  },
  SWRConfig?: ISWRConfig<ListDivisionsResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<ListDivisionsResponseModel, IResponseError>(
    {
      url: `/Clients/${clientId}/Divisions`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListClientsFunctionRequest = (
  {
    page,
    perPage,
    orderBy,
    orderByDirection,
    filter,
  }: {
    filter?: string;
    orderBy: number;
    orderByDirection: number;
    page: number;
    perPage: number;
  },
  SWRConfig?: ISWRConfig<ListClientsResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<ListClientsResponseModel, IResponseError>(
    {
      url: `/Clients/List`,
      method: "get",
      headers: {},
      params: {
        page,
        perPage,
        orderBy,
        orderByDirection,
        filter,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListDivisionsFunctionRequest = (
  SWRConfig?: ISWRConfig<ListDivisionsResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<ListDivisionsResponseModel, IResponseError>(
    {
      url: `/Client/Divisions`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListFourWeekPlansFunctionRequest = (
  {
    planIds,
    xClientCode,
  }: {
    planIds?: string[];
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<ActiveAndAvailableFourWeekPlanSummaries, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<ActiveAndAvailableFourWeekPlanSummaries, IResponseError>(
    {
      url: `/Content/FourWeekPlans`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      params: {
        planIds,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListLeaderboardsFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<Leaderboard[], IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<Leaderboard[], IResponseError>(
    {
      url: `/Content/Leaderboards`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListLearningMaterialsFunctionRequest = (
  {
    page,
    maxResults,
    categories,
    subCategories,
    xClientCode,
  }: {
    categories?: string[];
    maxResults?: number;
    page?: number;
    subCategories?: string[];
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<LearningMaterialSummary[], IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<LearningMaterialSummary[], IResponseError>(
    {
      url: `/Content/LearningMaterials`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      params: {
        page,
        maxResults,
        categories,
        subCategories,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListMiniHealthChecksFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<AvailableAndCompletedMiniHealthCheckSummaries, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<AvailableAndCompletedMiniHealthCheckSummaries, IResponseError>(
    {
      url: `/Content/MiniHealthChecks`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListModulesFunctionRequest = (SWRConfig?: ISWRConfig<ListModulesResponseModel, IResponseError>, axiosConfig?: AxiosRequestConfig) =>
  useGetRequest<ListModulesResponseModel, IResponseError>(
    {
      url: `/Modules/List`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListLocaleFunctionRequest = (SWRConfig?: ISWRConfig<ListLocaleResponseModel, IResponseError>, axiosConfig?: AxiosRequestConfig) =>
  useGetRequest<ListLocaleResponseModel, IResponseError>(
    {
      url: `/Locale/List`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListTargetGroupsFunctionRequest = (
  {
    page,
    perPage,
    orderBy,
    orderByDirection,
    nameFilter,
  }: {
    nameFilter?: string;
    orderBy: number;
    orderByDirection: number;
    page: number;
    perPage: number;
  },
  SWRConfig?: ISWRConfig<ListTargetGroupsResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<ListTargetGroupsResponseModel, IResponseError>(
    {
      url: `/TargetGroup/List`,
      method: "get",
      headers: {},
      params: {
        page,
        perPage,
        orderBy,
        orderByDirection,
        nameFilter,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListUserCurrentTrackerValuesFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<UserCurrentTrackerValue[], IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<UserCurrentTrackerValue[], IResponseError>(
    {
      url: `/Tracker/Values`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useListWisdomUsersFunctionRequest = (
  {
    page,
    perPage,
    orderBy,
    orderByDirection,
    keyword,
    clientId,
    divisionId,
  }: {
    clientId?: string;
    divisionId?: string;
    keyword?: string;
    orderBy: number;
    orderByDirection: number;
    page: number;
    perPage: number;
  },
  SWRConfig?: ISWRConfig<WisdomUsersResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<WisdomUsersResponseModel, IResponseError>(
    {
      url: `/UserAdmin/WisdomUsers/List`,
      method: "get",
      headers: {},
      params: {
        page,
        perPage,
        orderBy,
        orderByDirection,
        keyword,
        clientId,
        divisionId,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useLogReportingEventFunctionRequest = (
  mutationConfig?: SWRMutationConfig<LogReportingEventFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<LogReportingEventFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Reports/LogReportingEvent`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useLookupClientsFunctionRequest = (
  {
    clientName,
  }: {
    clientName: string;
  },
  SWRConfig?: ISWRConfig<LookupClientsResponseModel, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<LookupClientsResponseModel, IResponseError>(
    {
      url: `/UserAdmin/ClientsLookup`,
      method: "get",
      headers: {},
      params: {
        clientName,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const usePreviewLeaderboardFunctionRequest = (
  {
    leaderboardCode,
    xClientCode,
  }: {
    leaderboardCode?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<LeaderboardPreview, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<LeaderboardPreview, IResponseError>(
    {
      url: `/Leaderboard/Preview/${leaderboardCode}`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useRequestCallbackFunctionRequest = (
  mutationConfig?: SWRMutationConfig<RequestCallbackFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<RequestCallbackFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Contact/RequestCallback`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useSearchLearningMaterialsFunctionRequest = (
  {
    maxResults,
    text,
    xClientCode,
  }: {
    maxResults?: number;
    text?: string;
    xClientCode?: string;
  },
  SWRConfig?: ISWRConfig<LearningMaterialSummary[], IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useGetRequest<LearningMaterialSummary[], IResponseError>(
    {
      url: `/Content/LearningMaterials/Search`,
      method: "get",
      headers: { "X-Client-Code?": xClientCode },
      params: {
        maxResults,
        text,
      },
      ...axiosConfig,
    },
    SWRConfig
  );

export const useSendPushNotificationFunctionRequest = (
  mutationConfig?: SWRMutationConfig<SendPushNotificationFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<SendPushNotificationFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/PushNotifications`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useSetUserDeviceTokenFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<SetUserDeviceTokenFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<SetUserDeviceTokenFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/User/DeviceToken/Value`,
    method: "post",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useSetUserTrackerGoalFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<SetUserTrackerGoalFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<SetUserTrackerGoalFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Tracker/Goal`,
    method: "post",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useSetUserTrackerValueFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<SetUserTrackerValueFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<SetUserTrackerValueFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Tracker/Value`,
    method: "post",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useStartFourWeekPlanFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<StartFourWeekPlanFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<StartFourWeekPlanFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Content/FourWeekPlans/Start`,
    method: "post",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useSubmitFourWeekPlanActivitiesFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<SubmitFourWeekPlanActivitiesFunctionRequest, AxiosResponse<undefined>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<SubmitFourWeekPlanActivitiesFunctionRequest, AxiosResponse<undefined>, IResponseError>({
    url: `/Content/FourWeekPlans/Activities`,
    method: "post",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useUpdateUserFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<UpdateUserFunctionRequest, AxiosResponse<UserResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<UpdateUserFunctionRequest, AxiosResponse<UserResponseModel>, IResponseError>({
    url: `/User/Update`,
    method: "post",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useUpdateUserInterestsFunctionRequest = (
  {
    xClientCode,
  }: {
    xClientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<UpdateUserInterestsFunctionRequest, AxiosResponse<UserInterestResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<UpdateUserInterestsFunctionRequest, AxiosResponse<UserInterestResponseModel>, IResponseError>({
    url: `/User/Interests/Update`,
    method: "put",
    headers: { "X-Client-Code?": xClientCode, "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useUploadImageFunctionRequest = (
  {
    clientCode,
  }: {
    clientCode?: string;
  },
  mutationConfig?: SWRMutationConfig<UploadImageFunctionRequest, AxiosResponse<UploadImageResponse>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<UploadImageFunctionRequest, AxiosResponse<UploadImageResponse>, IResponseError>({
    url: `/UserAdmin/Images/Upload/${clientCode}`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    mutationConfig,
    axiosConfig,
  });

export const useUpsertTargetGroupFunctionRequest = (
  mutationConfig?: SWRMutationConfig<UpsertTargetGroupFunctionRequest, AxiosResponse<TargetGroupResponseModel>, IResponseError>,
  axiosConfig?: AxiosRequestConfig
) =>
  useMutationRequest<UpsertTargetGroupFunctionRequest, AxiosResponse<TargetGroupResponseModel>, IResponseError>({
    url: `/TargetGroup`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    mutationConfig,
    axiosConfig,
  });

export const useVerifyAdminUserFunctionRequest = (SWRConfig?: ISWRConfig<AdminUserResponseModel, IResponseError>, axiosConfig?: AxiosRequestConfig) =>
  useGetRequest<AdminUserResponseModel, IResponseError>(
    {
      url: `/UserAdmin/Verify`,
      method: "get",
      headers: {},
      ...axiosConfig,
    },
    SWRConfig
  );

export interface CompleteMiniHealthCheckFunctionRequest {
  body: CompleteMiniHealthCheckCommand;
}

export interface ContactSupportFunctionRequest {
  body: ContactSupportCommand;
}

export interface CreateClientAdminUserFunctionRequest {
  body: CreateClientAdminUserCommand;
}

export interface CreateClientFunctionRequest {
  body: CreateClientCommand;
}

export interface CreateHaAdminUserFunctionRequest {
  body: CreateHaAdminUserCommand;
}

export interface CreateLeaderboardFunctionRequest {
  body: CreateLeaderboardCommand;
}

export interface DeleteClientAdminUserFunctionRequest {
  query: {
    id: string;
  };
}

export interface DeleteClientFunctionRequest {
  query: {
    id: string;
  };
}

export interface DeleteHaAdminUserFunctionRequest {
  query: {
    id: string;
  };
}

export interface DeleteImageFunctionRequest {
  query: {
    clientCode: string;
    fileName: string;
  };
}

export interface DeleteTargetGroupFunctionRequest {
  query: {
    id: string;
  };
}

export interface DeleteUserTrackerValueFunctionRequest {
  query: {
    date?: string;
    trackerType?: string;
  };
}

export interface DeleteWisdomUserFunctionRequest {
  query: {
    id: string;
  };
}

export interface EditAdminUserAdditionalInfoFunctionRequest {
  body: EditAdminUserAdditionalInfoCommand;
}

export interface EditClientAdminUserFunctionRequest {
  body: EditClientAdminUserCommand;
}

export interface EditClientFunctionRequest {
  body: EditClientCommand;
}

export interface EditHaAdminUserFunctionRequest {
  body: EditHaAdminUserCommand;
}

export interface FinishFourWeekPlanFunctionRequest {
  body: FinishFourWeekPlanCommand;
}

export interface GetClientFunctionRequest {
  body: GetClientQuery;
}

export interface GetLeaderboardFunctionRequest {
  query: {
    leaderboardCode?: string;
  };
}

export interface GetReportFunctionRequest {
  query: {
    clientId?: string;
    dateFrom: string;
    dateTo: string;
    divisionId?: string;
    groupBy?: string;
    reportType: string;
    targetGroupClientIds?: string[];
  };
}

export interface GetUserAchievementsFunctionRequest {
  query: {
    from?: string;
    to?: string;
    trackerType: TrackerTypeTrackerType;
  };
}

export interface GetUserTrackerValuesFunctionRequest {
  query: {
    from?: string;
    to?: string;
    trackerType?: string;
  };
}

export interface ImportClientsFunctionRequest {
  body: FormData;
}

export interface JoinLeaderboardFunctionRequest {
  body: JoinLeaderboardCommand;
}

export interface LeaveLeaderboardFunctionRequest {
  body: LeaveLeaderboardCommand;
}

export interface ListAdminPermissionsFunctionRequest {
  query: {
    role: string;
  };
}

export interface ListAdminUsersFunctionRequest {
  body: ListAdminUsersQuery;
}

export interface ListClientsFunctionRequest {
  query: {
    filter?: string;
    orderBy: number;
    orderByDirection: number;
    page: number;
    perPage: number;
  };
}

export interface ListFourWeekPlansFunctionRequest {
  query: {
    planIds?: string[];
  };
}

export interface ListLearningMaterialsFunctionRequest {
  query: {
    categories?: string[];
    maxResults?: number;
    page?: number;
    subCategories?: string[];
  };
}

export interface ListTargetGroupsFunctionRequest {
  query: {
    nameFilter?: string;
    orderBy: number;
    orderByDirection: number;
    page: number;
    perPage: number;
  };
}

export interface ListWisdomUsersFunctionRequest {
  query: {
    clientId?: string;
    divisionId?: string;
    keyword?: string;
    orderBy: number;
    orderByDirection: number;
    page: number;
    perPage: number;
  };
}

export interface LogReportingEventFunctionRequest {
  body: LogReportingEventCommand;
}

export interface LookupClientsFunctionRequest {
  query: {
    clientName: string;
  };
}

export interface RequestCallbackFunctionRequest {
  body: RequestCallbackCommand;
}

export interface SearchLearningMaterialsFunctionRequest {
  query: {
    maxResults?: number;
    text?: string;
  };
}

export interface SendPushNotificationFunctionRequest {
  body: SendPushNotificationCommand;
}

export interface SetUserDeviceTokenFunctionRequest {
  body: SetUserDeviceTokenCommand;
}

export interface SetUserTrackerGoalFunctionRequest {
  body: SetUserTrackerGoalCommand;
}

export interface SetUserTrackerValueFunctionRequest {
  body: SetUserTrackerValueCommand;
}

export interface StartFourWeekPlanFunctionRequest {
  body: StartFourWeekPlanCommand;
}

export interface SubmitFourWeekPlanActivitiesFunctionRequest {
  body: SubmitFourWeekPlanActivitiesCommand;
}

export interface UpdateUserFunctionRequest {
  body: UpdateUserCommand;
}

export interface UpdateUserInterestsFunctionRequest {
  body: UpdateUserInterestsCommand;
}

export interface UploadImageFunctionRequest {
  body: FormData;
}

export interface UpsertTargetGroupFunctionRequest {
  body: UpsertTargetGroupCommand;
}

export type TrackerTypeTrackerType = 0 | 1 | 2 | 3 | 4 | 5;

export type CreateLeaderboardCommandTrackerType = 0 | 1 | 2 | 3 | 4 | 5;

export type CreateLeaderboardResponseTrackerType = 0 | 1 | 2 | 3 | 4 | 5;

export type DateOnlyDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type LeaderboardWithParticipantsTrackerType = 0 | 1 | 2 | 3 | 4 | 5;

export type ListAdminUsersQueryOrderBy = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type ListAdminUsersQueryOrderByDirection = 0 | 1;

export type LogReportingEventCommandReportingEventType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;

export type SetUserTrackerGoalCommandTrackerType = 0 | 1 | 2 | 3 | 4 | 5;

export type SetUserTrackerValueCommandTrackerType = 0 | 1 | 2 | 3 | 4 | 5;

export type UserCurrentTrackerValueTrackerType = 0 | 1 | 2 | 3 | 4 | 5;

export type UserLeaderboardSummaryTrackerType = 0 | 1 | 2 | 3 | 4 | 5;

export interface Achievement {
  id: string;
  name: string;
  notificationMessage: string;
  notificationTitle: string;
  type: AchievementType;
}

export interface AchievementType {
  fourWeekPlan?: FourWeekPlanSummary;
  identifier: string;
  numberOfDaysTracked?: number | null;
  totalOverPeriod?: number | null;
  trackerRelated?: boolean | null;
}

export interface ActiveAndAvailableFourWeekPlanSummaries {
  active: FourWeekPlanSummaryWithProgress[];
  available: FourWeekPlanSummaryWithProgress[];
}

export interface AdminPermissionsResponseModel {
  permissions: PermissionResponse[];
}

export interface AdminUserClientResponseModel {
  id: string;
  name: string;
}

export interface AdminUserDivisionResponseModel {
  id: string;
  name: string;
}

export interface AdminUserResponseModel {
  client?: AdminUserClientResponseModel;
  createdDate: string;
  division?: AdminUserDivisionResponseModel;
  email: string;
  forenames?: string;
  id: string;
  isActive: boolean;
  permissions: string[];
  phoneNumber?: string;
  role: string;
  subjectId?: string | null;
  surname?: string;
}

export interface AdminUsersResponseModel {
  metadata?: Metadata;
  records?: AdminUserResponseModel[];
}

export interface AnimationColour {
  hex: string;
}

export interface Audio {
  url: string;
}

export interface AvailableAndCompletedMiniHealthCheckSummaries {
  available: MiniHealthCheckSummary[];
  completed: MiniHealthCheckSummary[];
}

export interface AvailableUserAchievement {
  achievementId: string;
  achievementName: string;
}

export interface AwardedUserAchievement {
  achievementId: string;
  achievementName: string;
  dateFrom: DateOnly;
  dateTo: DateOnly;
  id: string;
  userId: string;
}

export interface Block {
  id?: string;
  type?: string;
  videoId?: string;
  videoType?: string;
}

export interface BreathingExercise {
  animationColour: AnimationColour;
  audioClip: Audio;
  id: string;
  name: string;
  stages: BreathingExerciseStage[];
  textIntro: Content;
  voiceIntro: Audio;
}

export interface BreathingExerciseStage {
  duration: number;
  stageType: string;
}

export interface Category {
  id: string;
  name: string;
}

export interface ClientLookupModel {
  divisions?: DivisionLookupModel[];
  id?: string;
  name?: string;
}

export interface ClientModuleResponseModel {
  id: string;
  key: string;
}

export interface ClientResponseModel {
  code: string;
  createdDate: string;
  customLogo: string;
  divisions?: DivisionResponseModel[];
  localeId: string;
  id: string;
  modules?: ClientModuleResponseModel[];
  name: string;
  phoneNumber: string;
  schemeNumber?: string;
  userLimit: number;
}

export interface CompleteMiniHealthCheckCommand {
  checkId: string;
  name: string;
  resultId: string;
  score: number;
}

export interface CompletedMiniHealthCheck {
  checkId: string;
  completedDate: DateOnly;
  resultId: string;
  score: number;
}

export interface ConfirmClientCodeResponseModel {
  clientLogo?: string;
  clientName?: string;
}

export interface ContactSupportCommand {
  message?: string;
}

export interface Content {
  blocks?: Block[];
  value?: ContentValue;
}

export interface ContentNode {
  attribution?: string;
  children?: ContentNode[];
  item?: string;
  level?: number | null;
  marks?: string[];
  meta?: ContentNodeLinkMetadata[];
  style?: string;
  type?: string;
  url?: string;
  value?: string;
}

export interface ContentNodeLinkMetadata {
  id?: string;
  value?: string;
}

export interface ContentValue {
  document?: ContentNode;
  schema?: string;
}

export interface CreateClientAdminUserCommand {
  clientId: string;
  divisionId?: string | null;
  emailAddress: string;
  permissions: string[];
}

export interface CreateClientCommand {
  code: string;
  divisions: DivisionModel[];
  modules: string[];
  name: string;
  phoneNumber: string;
  schemeNumber?: string;
  localeId: string;
  userLimit: number;
}

export interface CreateHaAdminUserCommand {
  emailAddress: string;
  permissions: string[];
}

export interface CreateLeaderboardCommand {
  endDate: DateOnly;
  id: string;
  name: string;
  startDate: DateOnly;
  trackerType: CreateLeaderboardCommandTrackerType | null;
}

export interface CreateLeaderboardResponse {
  code: string;
  endDate: DateOnly;
  id: string;
  name: string;
  startDate: DateOnly;
  trackerType: CreateLeaderboardResponseTrackerType;
}

export interface DataDimension {
  key?: string;
  value?: DataPoint[];
}

export interface DataPoint {
  key?: string;
  value?: number;
}

export interface DateOnly {
  day?: number;
  dayNumber?: number;
  dayOfWeek?: DateOnlyDayOfWeek;
  dayOfYear?: number;
  month?: number;
  year?: number;
}

export interface DivisionLookupModel {
  id?: string;
  name?: string;
}

export interface DivisionResponseModel {
  id?: string;
  name?: string;
  localeId: string;
}

export interface EditAdminUserAdditionalInfoCommand {
  forenames: string;
  surname: string;
}

export interface EditClientAdminUserCommand {
  divisionId?: string | null;
  permissions: string[];
  userId: string;
}

export interface EditClientModule {
  name: string;
}

export interface DivisionModel {
  name?: string;
  localeId: string;
}

export interface EditClientCommand {
  code: string;
  divisions: DivisionModel[];
  id: string;
  modules: string[];
  name: string;
  localeId?: string;
  phoneNumber: string;
  schemeNumber?: string;
  userLimit: number;
}

export interface EditHaAdminUserCommand {
  permissions: string[];
  userId: string;
}

export interface FinishFourWeekPlanCommand {
  challengeNotes?: string;
  successNotes?: string;
  userFourWeekPlanId?: string;
}

export interface FourWeekPlanActivities {
  activities?: FourWeekPlanActivity[];
}

export interface FourWeekPlanActivity {
  isCompleted?: boolean;
  text?: string;
}

export interface FourWeekPlanSummary {
  description: string;
  id: string;
  name: string;
}

export interface FourWeekPlanSummaryWithProgress {
  daysRemaining?: number | null;
  description: string;
  id: string;
  name: string;
}

export interface FourWeekPlanSummaryWithTrackersAndCategories {
  categories?: Category[];
  content?: Content;
  description: string;
  id: string;
  name: string;
  trackers?: Tracker[];
}

export interface GetClientQuery {
  clientId: string;
}

export interface Image {
  id: string;
  title: string;
  url: string;
}

export interface ImportClientsResponse {
  recordsProcessedCount: number;
}

export interface Interest {
  categoryId: string;
  id: string;
}

export interface JoinLeaderboardCommand {
  leaderboardCode: string;
}

export interface Leaderboard {
  description: Content;
  id: string;
  name: string;
  type: LeaderboardType;
}

export interface LeaderboardParticipant {
  id: string;
  name: string;
  score: number;
}

export interface LeaderboardPreview {
  code?: string;
  currentNumberOfParticipants?: number;
  endDate?: DateOnly;
  name?: string;
  startDate?: DateOnly;
  templateId?: string;
}

export interface LeaderboardType {
  identifier: string;
  name: string;
}

export interface LeaderboardWithParticipants {
  code: string;
  endDate: DateOnly;
  id: string;
  name: string;
  participants: LeaderboardParticipant[];
  startDate: DateOnly;
  templateId: string;
  trackerType: LeaderboardWithParticipantsTrackerType;
}

export interface LearningMaterial {
  articleType: string;
  categories: Category[];
  duration?: number | null;
  id: string;
  link?: string;
  text: Content;
  title: string;
}

export interface LearningMaterialSummary {
  articleType: string;
  categories: Category[];
  createdAt: string;
  duration?: number | null;
  id: string;
  image: Image;
  title: string;
}

export interface LeaveLeaderboardCommand {
  leaderboardCode?: string;
}

export interface ListAdminUsersFilter {
  clientId?: string | null;
  divisionId?: string | null;
  keyword?: string;
}

export interface ListAdminUsersQuery {
  filter?: ListAdminUsersFilter;
  orderBy?: ListAdminUsersQueryOrderBy;
  orderByDirection?: ListAdminUsersQueryOrderByDirection;
  page?: number;
  perPage?: number;
  roleId?: string;
}

export interface ListClientsResponseModel {
  metadata?: Metadata;
  records?: ClientResponseModel[];
}

export interface ListDivisionsResponseModel {
  divisions?: DivisionResponseModel[];
}

export interface ListModuleResponseModel {
  id: string;
  key: string;
}

export interface ListModulesResponseModel {
  modules: ListModuleResponseModel[];
}

export interface ListLocaleModel {
  id: string;
  code: string;
  description: string;
  defaultValue: boolean;
}

export interface ListLocaleResponseModel {
  locales: ListLocaleModel[];
}

export interface ListTargetGroupsResponseModel {
  metadata: Metadata;
  records: TargetGroupResponseModel[];
}

export interface LogReportingEventCommand {
  reportingEventType?: LogReportingEventCommandReportingEventType;
}

export interface LookupClientsResponseModel {
  clients?: ClientLookupModel[];
  totalCount?: number;
}

export interface Metadata {
  page?: number;
  pageCount?: number;
  perPage?: number;
  totalCount?: number;
}

export interface MiniHealthCheck {
  categories: Category[];
  description: string;
  fourWeekPlans: FourWeekPlanSummary[];
  id: string;
  intro: Content;
  name: string;
  questions: MiniHealthCheckQuestion[];
  questionsIntro: Content;
  results: MiniHealthCheckResult[];
  trackers: Tracker[];
}

export interface MiniHealthCheckQuestion {
  id: string;
  options?: MiniHealthCheckQuestionOption[];
  questionType: string;
  title: string;
}

export interface MiniHealthCheckQuestionOption {
  id: string;
  next?: MiniHealthCheckQuestion[];
  score?: number | null;
  text: string;
}

export interface MiniHealthCheckResult {
  content: Content;
  id: string;
  maxScore: number;
  minScore: number;
  title: string;
}

export interface MiniHealthCheckSummary {
  description: string;
  id: string;
  name: string;
  questionCount: number;
}

export interface Page {
  content?: Content;
  id?: string;
  name?: string;
  public?: boolean;
  slug?: string;
}

export interface PermissionResponse {
  id: string;
  key: string;
}

export interface ProgressEntry {
  day?: number;
  percentageComplete?: number;
}

export interface ReportResponseModel {
  dataDimensions?: DataDimension[];
}

export interface RequestCallbackCommand {
  callbackTime?: string;
  canSendSms?: string;
  leaveVoicemail?: string;
  phoneNumber?: string;
  reasonForCalling?: string;
}

export interface SendPushNotificationCommand {
  clientId?: string | null;
  content: string;
  divisionId?: string | null;
  link?: string;
  targetGroupId?: string | null;
  title: string;
  localeId?: string | null;
  onlyPerksAndDiscounts?: boolean | null;
  perksAndDiscountsLocaleId?: string | null;
}

export interface SetUserDeviceTokenCommand {
  token: string;
}

export interface SetUserTrackerGoalCommand {
  goal: number;
  trackerType: SetUserTrackerGoalCommandTrackerType | null;
}

export interface SetUserTrackerValueCommand {
  date: DateOnly;
  manual?: boolean;
  trackerType: SetUserTrackerValueCommandTrackerType | null;
  value: number;
}

export interface StartFourWeekPlanCommand {
  fourWeekPlanDatoId?: string;
}

export interface SubmitFourWeekPlanActivitiesCommand {
  activities: FourWeekPlanActivity[];
  datoFourWeekPlanId: string;
}

export interface TargetGroupClientInfo {
  id: string;
  name: string;
}

export interface TargetGroupResponseModel {
  clients: TargetGroupClientInfo[];
  createdDate: string;
  id: string;
  name: string;
}

export interface Tracker {
  description: string;
  id: string;
  ident: string;
  name: string;
}

export interface TrackerRelatedMiniHealthCheckSummary {
  description: string;
  id: string;
  name: string;
}

export interface TrackerWithRelatedContent {
  achievements: Achievement[];
  description: string;
  fourWeekPlans: FourWeekPlanSummary[];
  id: string;
  identifier: string;
  learningMaterialCategories: Category[];
  miniHealthChecks: TrackerRelatedMiniHealthCheckSummary[];
  name: string;
}

export interface UpdateUserCommand {
  dateOfBirth?: DateOnly;
  divisionId?: string | null;
  forenames?: string;
  gender?: string;
  phoneNumber?: string;
  surname?: string;
}

export interface UpdateUserInterestsCommand {
  interestCategoryIds: string[];
}

export interface UploadImageResponse {
  url?: string;
}

export interface UpsertTargetGroupCommand {
  clientIds: string[];
  id?: string | null;
  name: string;
}

export interface UserAchievements {
  availableUserAchievements?: AvailableUserAchievement[];
  awardedUserAchievements?: AwardedUserAchievement[];
}

export interface UserClientResponseModel {
  code: string;
  id: string;
  logoUrl?: string;
  modules?: string[];
  name: string;
}

export interface UserCurrentTrackerValue {
  goal: number;
  trackerType: UserCurrentTrackerValueTrackerType;
  value?: number | null;
}

export interface UserDivisionResponseModel {
  id: string;
  name: string;
}

export interface UserFourWeekPlanProgress {
  challengeNotes?: string;
  entries?: ProgressEntry[];
  startDate?: DateOnly;
  successNotes?: string;
  userFourWeekPlanId?: string | null;
}

export interface UserInterestPreference {
  categoryId: string;
  isSelected: boolean;
  name: string;
}

export interface UserInterestPreferences {
  interestPreferences: UserInterestPreference[];
}

export interface UserInterestResponseModel {
  interests: Interest[];
}

export interface UserLeaderboardSummary {
  code: string;
  daysBeforeStart?: number | null;
  daysRemaining?: number | null;
  durationInDays: number;
  id: string;
  name: string;
  participantCount: number;
  trackerType: UserLeaderboardSummaryTrackerType;
  userPosition?: number | null;
}

export interface UserLeaderboards {
  active: UserLeaderboardSummary[];
  completed: UserLeaderboardSummary[];
}

export interface UserMiniHealthCheck {
  completed?: CompletedMiniHealthCheck;
  miniHealthCheck: MiniHealthCheck;
}

export interface UserPerClientRecord {
  accountName?: string;
  code?: string;
  numberOfUsers?: number;
}

export interface UserResponseModel {
  client?: UserClientResponseModel;
  createdDateUtc: string;
  dateOfBirth?: DateOnly;
  division?: UserDivisionResponseModel;
  email: string;
  forenames: string;
  gender?: string;
  helplinePhoneNumber: string;
  id: string;
  interests: Interest[];
  phoneNumber?: string;
  surname: string;
  localeId?: string;
}

export interface UserTrackerValue {
  date: DateOnly;
  value: number;
}

export interface UserTrackerValues {
  goal: number | null;
  values: UserTrackerValue[];
}

export interface UsersPerClientResponse {
  records?: UserPerClientRecord[];
}

export interface ValidationExceptionErrorResponse {
  code?: string;
  message?: string;
  property?: string;
  value?: { [key: string]: any };
}

export interface ValidationExceptionResponse {
  errors?: ValidationExceptionErrorResponse[];
  message?: string;
}

export interface WisdomUserClientResponseModel {
  id: string;
  name: string;
}

export interface WisdomUserDivisionResponseModel {
  id: string;
  name: string;
}

export interface WisdomUserResponseModel {
  client?: WisdomUserClientResponseModel;
  createdDateUtc: string;
  dateOfBirth?: DateOnly;
  division?: WisdomUserDivisionResponseModel;
  email: string;
  forenames: string;
  gender?: string;
  id: string;
  phoneNumber?: string;
  surname: string;
}

export interface WisdomUsersResponseModel {
  metadata?: Metadata;
  records?: WisdomUserResponseModel[];
}
