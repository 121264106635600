import { ReactComponent as PasswordManagementIcon } from "shared/assets/Icons/password-management.svg";
import { ReactComponent as SingleSignOnIcon } from "shared/assets/Icons/single-sign-on.svg";
import { ReactComponent as TwoFactorIcon } from "shared/assets/Icons/two-factor.svg";
import { AccountSecurityModule } from "./interfaces";

export const accountSecurityModulesData: AccountSecurityModule[] = [
  {
    title: "Password Management",
    description: "Update your password, changing your password will affect any other services provided by Wisdom.",
    ctaText: "Change password",
    ctaLink: "password-management",
    Icon: PasswordManagementIcon,
  },
  {
    title: "Single sign-on (SSO)",
    description:
      "Once set up, Single sign-on allows you to log in once and access services without re-entering your password every time you log into your Wisdom account.",
    ctaText: "Settings",
    ctaLink: "single-signon",
    info: {
      title: "What is Single Sign-on (SSO)?",
      description:
        "Single sign-on (SSO) is an authentication tool that lets you securely access multiple applications and services using just one set of credentials. Put simply, our SSO feature means you can log in to your Wisdom account in one go, with details you're already familiar with. Just link a Microsoft, Google or Apple account, sign in once, then move between Wisdom applications in a single click.",
      steps: [
        "Use Single Sign-on (SSO) and get:<br/>Fewer interruptions; Work longer sessions without stopping for sign-in prompts.",
        "One memorable email and password<br/>Access your Bright account with a login you already know.",
        "Better login security<br/>Double down on authentication by linking an account you trust.",
      ],
      endNote:
        "Got a question about your Microsoft, Google or Apple accounts? Please contact their respective call centres to speak to their experts.",
    },
    Icon: SingleSignOnIcon,
  },
  {
    title: "Two-factor authentication (2FA)",
    description: "Once you set up 2FA, we'll ask for an authentication code every time you log into your Wisdom account.",
    ctaText: "Set up",
    ctaLink: "two-factor-auth",
    info: {
      title: "What is Two-Factor authentication?",
      description:
        "How Two-Factor authentication works Two-Factor authentication - sometimes called “2FA” - works by adding an additional layer of security to your online accounts. It requires an additional login credential - beyond just the username and password - to gain account access, and getting that second credential requires access to something that belongs to you.",
      steps: [
        "Step 1<br/>Turn on Two-Factor authentication<br/>By default, Two-Factor authentication is not active unless you, the user, turns it on. Once turned on you'll beinvited to download a third party authenticator app from your mobile phone app store.",
        "Step 2<br/>Download an authenticator app<br/>An authentication app works by using a mobile app to generate an 6-digit authentication code.<br/><br/>Don't have an authenticator app?<br/>Download one from: Apple App Store or Google Play Store",
        "Step 3<br/>Scan your QR code<br />Scan your QR code with your authenticator app. This will generate a 6-digit code.",
        "Step 4<br/>Don't forget your recovery codes!<br/>What happens if you enable Two-Factor authentication but you don't have access to your phone the next time you attempt to login? This is where you recovery codes come in.<br/><br/>Download, print and store your recovery codes in a safe place.",
      ],
      stepBannerInsert:
        "<strong>Remember:</strong> These recovery codes are your way in just in case you lose your phone - so don't store them on your phone!",
      endNote:
        "<strong>Next time you login...</strong><br/>Once Two-Factor authentication is enabled on your account you'll benefit from enhanced security. This means you'll need your authenticator app to login again. Failing that, you'll need your recovery codes.",
    },
    Icon: TwoFactorIcon,
  },
];
