import { Route, Routes } from "react-router-dom";
import { Preferences } from "./views/Preferences/Preferences";
import { PersonalDetails } from "./views/PersonalDetails/PersonalDetails";
import { AccountHome } from "./views/AccountHome/AccountHome";
import { ContactSupport } from "./views/ContactSupport/ContactSupport";
import { t } from "i18next";
import { AccountDatoPage } from "./Components/AccountDatoPage";
import { AccountSecurity } from "./views/AccountSecurity/AccountSecurity";

export const Account = () => {
  return (
    <Routes>
      <Route index element={<AccountHome />} />
      <Route path="preferences" element={<Preferences />} />
      <Route path="personal-details" element={<PersonalDetails />} />
      <Route path="terms" element={<AccountDatoPage heading={t("account.terms")} slug={"terms"} pageTitle={t("pageTitle.account.terms")} />} />
      <Route
        path="privacy"
        element={<AccountDatoPage heading={t("account.privacy")} slug={"privacy"} pageTitle={t("pageTitle.account.privacy")} />}
      />
      <Route
        path="cookie-policy"
        element={<AccountDatoPage heading={t("account.cookiePolicy")} slug={"cookie-policy"} pageTitle={t("pageTitle.account.cookiePolicy")} />}
      />
      <Route path="about" element={<AccountDatoPage heading={t("account.about")} slug={"about"} pageTitle={t("pageTitle.account.about")} />} />
      <Route path="contact-support" element={<ContactSupport />} />
      <Route path="account-security/*" element={<AccountSecurity />} />
    </Routes>
  );
};
