import { ReactComponent as AccountAvatarIcon } from "shared/assets/Icons/account-avatar.svg";
import { EqualWebButton } from "UIPalette/EqualWebButton/EqualWebButton";
import HealthAssuredLogo from "shared/assets/HealthAssuredLogo.png";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { SearchBar } from "./SearchBar";
import { t } from "i18next";
import useComponentVisible from "shared/UI/Hooks/useComponentVisible";
import { useLogout } from "../../../hooks/Logout/useLogout";
import { NavLink, useNavigate } from "react-router-dom";
import { getChangePasswordUrl } from "shared/core/hooks/getChangePasswordUrl";
import { useRedirect } from "shared/core/hooks/useRedirect";
import classNames from "classnames";

export const TopNavBar = () => {
  const navigateHook = useNavigate();
  const redirectHook = useRedirect();
  const logout = useLogout();
  const { targetAreaRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const showAccountSecurity = !!process.env.REACT_APP_SHOW_ACCOUNT_FEATURE_TOGGLE;

  const changePasswordUrl = getChangePasswordUrl(
    process.env.REACT_APP_AUTH_AUTHORITY!,
    process.env.REACT_APP_AUTH_CLIENT_ID!,
    `${window.location.origin}/auth`
  );

  return (
    <header role="banner" ref={targetAreaRef} className="flex flex-row bg-white h-20 w-full sticky top-0 z-OVERLAY drop-shadow-md md:px-[4rem]">
      <NavLink
        className="flex-grow lg:flex-grow-0 h-full px-3 flex xs:pointer-events-none lg:pointer-events-auto w-4/6 sm:w-auto items-center"
        aria-label={t("navigation.homeNavigationLabel")}
        to={"/home"}
      >
        <img alt={t("navigation.healthAssuredlogo")} src={HealthAssuredLogo} className="max-h-14 h-full" />
      </NavLink>
      <div className="hidden flex-grow lg:inline-flex h-full flex-row items-center gap-6 ml-4">
        <NavLink
          to={"/health-hub"}
          aria-label={t("navigation.healthHubNavigationLabel")}
          className={({ isActive }) => classNames("text-xl font-bold text-primary-700", { "no-underline": !isActive })}
        >
          {t("navigation.healthHubButton")}
        </NavLink>
        <NavLink
          to={"/contact-us"}
          aria-label={t("navigation.contactUsNavigationLabel")}
          className={({ isActive }) => classNames("text-xl font-bold text-primary-700", { "no-underline": !isActive })}
        >
          {t("navigation.contactUsButton")}
        </NavLink>
        <NavLink
          to={"/resources"}
          aria-label={t("navigation.resourcesNavigationLabel")}
          className={({ isActive }) => classNames("text-xl font-bold text-primary-700", { "no-underline": !isActive })}
        >
          {t("navigation.resourcesButton")}
        </NavLink>
      </div>
      <EqualWebButton />
      <SearchBar />
      <button
        className="hidden lg:inline-block h-full align-top mr-5 mt-0"
        aria-label={t("navigation.userAccountMenu.ariaUserMenu")}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <AccountAvatarIcon />
      </button>
      <Hideable hidden={!isComponentVisible}>
        <div className="hidden lg:block absolute right-5 top-20 z-OVERLAY w-48 rounded overflow-hidden shadow-sm flex-col bg-white">
          <button
            className="h-full w-full text-left p-2 hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.personalDetails")}
            onClick={() => navigateHook("/account/personal-details")}
          >
            <span>{t("navigation.userAccountMenu.personalDetails")}</span>
          </button>
          <button
            className="h-full w-full text-left p-2 hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.preferences")}
            onClick={() => navigateHook("/account/preferences")}
          >
            <span>{t("navigation.userAccountMenu.preferences")}</span>
          </button>
          <button
            className="h-full w-full text-left p-2 hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.about")}
            onClick={() => navigateHook("/account/about")}
          >
            <span>{t("navigation.userAccountMenu.about")}</span>
          </button>
          <button
            className="h-full w-full text-left p-2 hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.terms")}
            onClick={() => navigateHook("/account/terms")}
          >
            <span>{t("navigation.userAccountMenu.terms")}</span>
          </button>
          <button
            className="h-full w-full text-left p-2 hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.privacy")}
            onClick={() => navigateHook("/account/privacy")}
          >
            <span>{t("navigation.userAccountMenu.privacy")}</span>
          </button>
          <button
            className="h-full w-full text-left p-2 hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.cookiePolicy")}
            onClick={() => navigateHook("/account/cookie-policy")}
          >
            <span>{t("navigation.userAccountMenu.cookiePolicy")}</span>
          </button>
          <button
            className="h-full w-full text-left p-2 hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.contactSupport")}
            onClick={() => navigateHook("/account/contact-support")}
          >
            <span>{t("navigation.userAccountMenu.contactSupport")}</span>
          </button>
          <button
            className="h-full w-full text-left p-2 hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.changePassword")}
            onClick={() => redirectHook(changePasswordUrl)}
          >
            <span>{t("navigation.userAccountMenu.changePassword")}</span>
          </button>
          {showAccountSecurity && (
            <button
              className="h-full w-full text-left p-2 hover:bg-silver"
              aria-label={t("navigation.userAccountMenu.accountSecurity")}
              onClick={() => navigateHook("/account/account-security")}
            >
              <span>{t("navigation.userAccountMenu.accountSecurity")}</span>
            </button>
          )}
          <button
            className="h-full w-full text-left p-2 hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.logout")}
            onClick={() => logout()}
          >
            <span>{t("navigation.userAccountMenu.logout")}</span>
          </button>
        </div>
      </Hideable>
    </header>
  );
};
