export declare type CopyBlockProps = {
  content: string;
  className?: string;
};

export const CopyBlock = ({ content, className }: CopyBlockProps) => {
  return (
    <div
      className={`copyblock ${className || ""}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};
